import React, { useState, useEffect, useMemo } from 'react'
import { analytics, db, functions } from './firebase'
import { collection, addDoc, getDocs, getDoc, doc, where, query, onSnapshot, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions"
// import { logEvent } from '@firebase/analytics';
import { createPaypalOrder, handlePaypalOrder } from './paypal';
import { PayPalButton } from 'react-paypal-button-v2';
import { CircularProgress } from '@mui/material';
import { FcCheckmark } from "react-icons/fc"

const logEvent = console.log
const Loader = ({className}) => <svg class={"animate-spin h-5 w-5 text-white " + className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>




function userHasTrial(user) {
    let created = new Date(user.metadata.creationTime)

    let weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7)
    return weekAgo < created;
}

function daysLeftInTrial(user) {
    let created = new Date(user.metadata.creationTime)
    let weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7)
    return daysDiff(weekAgo, created) - 1;
}
function daysDiff(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}


export const LIFETIME = "Lifetime"
export const SUB = "Monthly"
export const FREE = "Free"


function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function weekStart(date) {
    let monday = getMonday(date)
    monday.setHours(0, 0, 0, 0)
    return monday
}

export async function getRemoteSettings() {
    let docRef = doc(db, 'settings', 'settings')
    let docSnap = await getDoc(docRef)
    if (docSnap.exists) return docSnap.data()
    else return { freeSongs: 5 }
}

export async function userFreeSongsLeft(uid) {
    return new Promise(async resolve => {
        // let usersRef = 
        let queriesRef = collection(db, 'users', uid, "queries")
        // let q = query(queriesRef, where('date', '<', new Date()))
        let myQueriesSnapshot = await getDocs(queriesRef)
        resolve(myQueriesSnapshot.size >= 5 ? 0 : 5 - myQueriesSnapshot.size)
    })
}


function subTypetoStripe(localType) {
    if (localType === LIFETIME) return "one_time"
    if (localType === SUB) return "recurring"
}

async function userHasSubscription(uid, user) {
    return new Promise(async resolve => {
        if (!uid) resolve(false);

        // 6s timeout
        setTimeout(() => resolve({ hasSubscription: false }), 6000)

        let subsRef = await collection(db, "customers", uid, "subscriptions")
        let q = query(subsRef, where('status', 'in', ['trialing', 'active']))
        let subsQuerySnapshot = await getDocs(q)

        let paymentsRef = await collection(db, "customers", uid, "payments")
        let paymentsQ = query(paymentsRef, where('status', 'in', ['succeeded']))
        let paymentsQuerySnapshot = await getDocs(paymentsQ)

        let paymentDocs = paymentsQuerySnapshot.docs.filter(payment => payment.data().items && payment.data().items[0].price.type === "one_time")


        let hasSub = subsQuerySnapshot.docs.length > 0
        let hasOneTime = paymentDocs.length > 0
        let res = {};
        if (hasOneTime || hasSub) {
            res = {
                hasSubscription: true,
                priceId: (paymentDocs[0]?.data()?.items[0]?.price?.id || subsQuerySnapshot?.docs[0]?.data()?.price_id),
                hasSub,
                hasOneTime,
                type: hasOneTime ? LIFETIME : (hasSub ? SUB : null)
            }
        } else {
            res = {
                hasSubscription: false,
                type: FREE,
                freeSongsLeft: await userFreeSongsLeft(uid)
            }
        }

        resolve(res)

    });
}

const getPortalLinkFunc = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
const getPortalLink = async () => {
    const { data } = await getPortalLinkFunc({
        returnUrl: window.location.origin,
        locale: "auto", // Optional, defaults to "auto"
        // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    return data.url
}

const CheckListItemSubtitle = ({ children, rest }) =>
    <div className="text-xs text-slate-500" >{children}</div>
const CheckListItem = ({ children, ...rest }) =>
    <div className="flex text-lg mb-1"  {...rest}>
        <FcCheckmark className="mx-2 mt-1" />
        <div>{children}</div>
    </div>
const CheckList = ({ items, children, ...rest }) =>
    <div>
        {children || items?.map(i => <CheckListItem key={`${i}`}>{i}</CheckListItem>)}
    </div>

export default function Dashboard({ user, setUser, }) {

    const [prices, setPrices] = useState(null)
    const [selectedPrice, setSelectedPrice] = useState(null)

    const [sub, setSub] = useState(null)
    const [loading, setLoading] = useState(false)

    const [portalLink, setPortalLink] = useState(null)

    useEffect(async () => {
        setLoading(true)
        let productsRef = await collection(db, 'products')
        let q = query(productsRef, where('active', '==', true))
        let querySnapshot = await getDocs(q)

        querySnapshot.forEach(async function (doc) {

            q = query(await collection(doc.ref, 'prices'), where('active', '==', true))
            const priceSnap = await getDocs(q);
            setPrices(priceSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })).sort(price => -price.unit_amount).filter(price => price.unit_amount > 10).filter(price => true || price.type === "one_time"))
            setLoading(false)
        });

        setSub(await userHasSubscription(user.uid, user))

    }, [])

    const goToPortal = async (price_id) => {
        setPrices(oldPrices => oldPrices.map(oldPrice => oldPrice.id === price_id ? { ...oldPrice, purchasing: true } : oldPrice))
        logEvent(analytics, "go to portal")
        let url = await getPortalLink()
        window.open(url)
        setPrices(oldPrices => oldPrices.map(oldPrice => oldPrice.id === price_id ? { ...oldPrice, purchasing: false } : oldPrice))
    }

    const purchaseOneTime = async (price_id) => {
        logEvent(analytics, "click purchase one-time")
        setLoading(true)
        setPrices(oldPrices => oldPrices.map(oldPrice => oldPrice.id === price_id ? { ...oldPrice, purchasing: true } : oldPrice))
        let userDoc = await doc(collection(db, "customers"), user.uid)
        let checkoutSessionsRef = await collection(userDoc, 'checkout_sessions')
        let checkoutDocRef = await addDoc(checkoutSessionsRef, {
            mode: "payment",
            price: price_id, // One-time price created in Stripe
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        })


        // Wait for the CheckoutSession to get attached by the extension
        onSnapshot(checkoutDocRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
                console.log(url)
                setLoading(false)
                setPrices(oldPrices => oldPrices.map(oldPrice => oldPrice.id === price_id ? { ...oldPrice, purchasing: false } : oldPrice))
            }
        });
    }

    const purchaseSub = async price_id => {
        logEvent(analytics, "click purchase sub")
        setLoading(true)
        setPrices(oldPrices => oldPrices.map(oldPrice => oldPrice.id === price_id ? { ...oldPrice, purchasing: true } : oldPrice))
        let userDoc = await doc(collection(db, "customers"), user.uid)
        let checkoutSessionsRef = await collection(userDoc, 'checkout_sessions')
        let checkoutDocRef = await addDoc(checkoutSessionsRef, {
            price: price_id, // One-time price created in Stripe
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        })

        onSnapshot(checkoutDocRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });

    }

    const priceToEl = price => <Price
        key={`price-${price.id}`}
        selectPrice={setSelectedPrice}
        updateSub={async () => setSub(await userHasSubscription(user.uid, user))}
        title={price.type === "one_time" ? "Unlimited Lifetime" : "Unlimited Monthly"}
        priceEl={<div>
            {price.currency == "usd" ? "$" : "€"}{(price.unit_amount / 100)} <span>{price.type !== 'one_time' ? "/ mo" : " one-time"}</span>
        </div>}
        descr={price.type === 'one_time' ?
            <CheckList items={['Pay once, use forever', 'Most popular', 'Highest user satisfaction']} /> :
            <CheckList items={['Cancellable monthly', 'No strings attached']} />}
        price={price} sub={sub} purchaseOneTime={purchaseOneTime} purchaseSub={purchaseSub} goToPortal={goToPortal} />


    console.log(prices)
    return (
        loading ? <div className="p-64 "><CircularProgress /></div> :
            <div className="flex p-12 px-20 flex-col relative w-full h-full" style={{ maxWidth: 1700 }}>
                {!sub?.hasSubscription && <p>You have {sub?.freeSongsLeft} song{sub?.freeSongsLeft !== 1 ? 's' : ''} left in the trial version.</p>}
                <h3 className="default-font  font-bold text-3xl">Unlimited version</h3>
                <div className="flex md:flex-row flex-col ">
                    <div className="w-full shrink-0 default-font py-8 px-6 flex items-center md:w-1/3">
                        <CheckList className="text-lg">
                            <CheckListItem>Unlimited songs</CheckListItem>
                            <CheckListItem>Automatic bpm & key lookup</CheckListItem>
                            <CheckListItem>Set or tap custom bpm</CheckListItem>
                            <CheckListItem>
                                <i>Follow video</i> functionality
                                <CheckListItemSubtitle>Skip around the video and the metronome stays in sync</CheckListItemSubtitle>
                            </CheckListItem>
                            <CheckListItem><i>Auto-Sync</i> functionality
                                <CheckListItemSubtitle>Metronome starts playing on beat automatically</CheckListItemSubtitle>
                            </CheckListItem>
                        </CheckList>
                    </div>
                    <div className="flex justify-center flex-wrap">
                        {/* {!sub?.hasSubscription && <Price title={"Limited version"} priceEl={<div><br/></div>} user={user} price={{ id: -1, type: "trial" }} descr={"Play with it, see if you like it."} />} */}
                        {prices?.filter(p => !selectedPrice || p === selectedPrice).map(priceToEl)}

                    </div>
                </div>

            </div>
    )
}
const Separator = () =>
    <div className="w-full bg-gray-200 m-4 rounded-md" style={{ height: 3 }} />

function Price({ price, title, priceEl, descr, selectPrice, goToPortal, updateSub, purchaseOneTime, user, purchaseSub, sub, }) {
    const [clickedPurchase, setClickedPurchase] = useState(false)
    return <div style={{ width: 360 }} className=" bg-gray-50 flex-shrink-0 rounded-md shadow-md items-center flex-col flex p-6 m-6 default-font">
        <div className=" w-full text-2xl mb-2 font-bold ">
            {title}
        </div>

        <div className="w-full text-4xl font-bold">
            {priceEl}
        </div>

        <Separator />
        <div className="w-full flex mb-4 text-slate-500">
            {descr}
        </div>

        <div className="mt-auto w-full">
            {price.type === "trial"
                ? <div className="flex h-10 items-center justify-center bg-white text-gray-400 text-md border-gray-400 border-2 w-full rounded-md p-2 transition-all">{daysLeftInTrial(user)} days left</div> :
                (subTypetoStripe(sub?.type) === price.type ?

                    (price.type === "one_time" ? <div className="flex h-10 items-center justify-center bg-white text-green-500 text-md border-green-500 border-2 w-full rounded-md p-2 transition-all">You own</div> : (goToPortal &&
                        <a onClick={() => goToPortal(price.id)} className="flex h-10 cursor-pointer items-center justify-center bg-white text-green-500 text-md border-green-500 border-2 w-full rounded-md p-2 transition-all">
                            {price.purchasing ? <Loader className="text-green-600"/> : "Manage Subscription"}
                        </a>))
                    : (clickedPurchase ? <div>
                        <PayPalButton
                            amount="12.00"
                            options={{
                                disableFunding: "card",
                                clientId: "AXmCIakgmDD9DQ-CrTCXk6Khh4A46IhS6APYBbX5LjP9makmFluSpC_BPuQpXrpfuekX5CRY6UmwNR4I"
                            }}
                            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                            createOrder={(data, actions) => createPaypalOrder().then(response => response.data.id)}
                            onApprove={async (data, actions) => {
                                await handlePaypalOrder({ orderId: data.orderID })
                                updateSub()
                            }}
                        />

                        <button
                            className="flex h-8 items-center justify-center bg-gray-800 text-white text-sm hover:opacity-90 w-full rounded-md p-2 shadow-md transition-all outline outline-0 focus:outline-4 active:outline-3 outline-blue-100 min-w-24"
                            onClick={() => purchaseOneTime(price.id)}
                        >{price.purchasing ? <Loader/> : "Pay with card"}</button>


                    </div> :
                        <button className="flex h-10 items-center justify-center bg-blue-500 text-white text-md font-bold hover:opacity-90 w-full rounded-md p-2 shadow transition-all outline outline-0 focus:outline-4 active:outline-3 outline-blue-100"
                            onClick={() => { if (price.type === "one_time") { setClickedPurchase(true); selectPrice(price) } else { purchaseSub(price.id) } }}>{price.purchasing ? <Loader/> : "Buy"}</button>))
            }</div>

    </div>
}