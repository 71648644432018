// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLrX_jCRctTsYr7oaOPyUoBGsgEa7BJjA",
  authDomain: "yt-metro.firebaseapp.com",
  projectId: "yt-metro",
  storageBucket: "yt-metro.appspot.com",
  messagingSenderId: "116930868269",
  appId: "1:116930868269:web:e411e63295440af5e9f4a4",
  measurementId: "${config.measurementId}"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const functions = getFunctions(app, "us-central1")
// connectFunctionsEmulator(functions, "localhost", 5001)

export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});
