import React, { useState } from 'react';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { app, auth } from './firebase';


import { GoogleAuthProvider } from "firebase/auth";
import { StyledFirebaseAuth } from "react-firebaseui"

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,

    ],
};


export default function Login({ user, setUser, signingUp, setSigningUp }) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [wantsToCreateAccount, setWantsToCreateAccount] = useState(false)

    const signUp = () => {
        console.log("singing up")
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log("singing up")
                // Signed in 
                const user = userCredential.user;
                setUser(user)
            })
            .catch((error) => {
                console.log("singing up")
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }
    const login = () => signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            setUser(user)
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });


    return <div className="flex flex-col w-full h-full items-center my-12 mb-60">
        <div className=" login-card px-8 py-6 text-left bg-white shadow-md rounded">
            <h3 className="font-bold text-center my-4 text-2xl">Login</h3>
            <div className={""}><StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /></div>
            {/* <p className={"text-center"}>or</p>

            <h3 className="text-2xl font-bold text-center hover:underline cursor-pointer" onClick={() => setWantsToCreateAccount(true)} >{signingUp ? "Create an account" : "Login to your account"}</h3>
            
            {wantsToCreateAccount && <form action="">
                <div className="mt-4">
                    <div>
                        <label className="block" htmlFor="email">{signingUp ? "Your email" : "Email"}</label>
                        <input type="text" placeholder="cool-musician@jazz.com" value={email} onChange={e => setEmail(e.target.value)}
                            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
                    </div>
                    <div className="mt-4">
                        <label className="block" htmlFor="password">{signingUp ? "Choose a password" : "Password"}</label>
                        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}
                            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
                    </div>
                    <div className="flex items-baseline justify-between">
                        {!signingUp ? <a href="#" className="text-sm text-blue-600 hover:underline" onClick={() => setSigningUp(true)}>Sign up</a> : <span />}
                        <button className="px-6 py-2 mt-4 ml-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900 shrink-0"
                            onClick={signingUp ? signUp : login}
                        >
                            {signingUp ? "Sign up" : "Login"}
                        </button>
                    </div>
                </div>
            </form>} */}


        </div>


        <p className={"mt-6 text-slate-400 w-full px-4 text-center text-xs flex-0"}>By creating an account you agree to the <a href="https://gist.github.com/andreemic/37a60c89866c7a0dd9d4e8d42ceb4d20" target="_blank">privacy policy</a> <br /> and <a href="https://gist.github.com/andreemic/096f01bc913dc99a593e348cddc78651" target="_blank"> Terms & conditions</a></p>
    </div>
}