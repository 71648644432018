
import './App.css';
import Login from './Login';
import React, { useState } from "react"
import "./firebase"
import Dashboard from './Dashboard';

import { analytics, auth } from './firebase';



import { onAuthStateChanged } from "firebase/auth";
import { logEvent, setUserId } from '@firebase/analytics';


function App() {
  let [signingUp, setSigningUp] = useState(true)
  let [user, setUser] = useState(undefined)

  onAuthStateChanged(auth, u => {
    setUser(u)
    setUserId(analytics, u.uid)
    logEvent(analytics, 'log-in', { uid: u.uid, ...u })
  });

  return (
    <div className="flex flex-col bg-white min-h-screen">
      <a href={"https://chrome.google.com/webstore/detail/youtube-metronome/fdkbpgmenembjcifameimdbakhkciidg"} className={"flex cursor-pointer justify-center items-center my-12"}>
        <div className={"fixed w-full z-20 left-0 top-0  flex bg-white py-6 px-12 "}>
          <img className={"w-8"} src="/metronome512.png"></img>

          <h1 className="text-2xl font-extrabold mx-2">
            Youtube Metronome
          </h1>

          {/* <div className={"font-bland text-xs absolute left-10"}>by Mikhail Andreev</div> */}
        </div>

      </a>
      <p className={"text-center"}>Here you can see and manage your subscription. <a className={"text-blue-400"} target="_blank" href="mailto:andreemic@gmail.com">Email me</a> if you have complaints, questions, requests, or need but can't afford a subscription.</p>



      {user && <div className="m-6">
        <a className="hover:underline cursor-pointer text-slate-400" onClick={() => auth.signOut()}>Logout ({user.email})</a>
      </div>}

      {user !== undefined && <div className="relative flex w-full h-full justify-center flex-1 ">


        {user ? <Dashboard user={user} setUser={setUser} /> : <div>
          <Login signingUp={signingUp} setSigningUp={setSigningUp} />

        </div>}

      </div>}
    </div>
  );
}

export default App;
